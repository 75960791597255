
/* index-2 */
.index-2{
  .home-slide{
    overflow: hidden;
    height: calc(100vh - 202px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    img{
      display: none;
    }
  }

  .tmblr-iframe{
    display: none!important;
  }

  .tumblr-container{
    -moz-column-count: 5;
    -moz-column-gap: $gutter;
    -webkit-column-count: 5;
    -webkit-column-gap: $gutter;
    column-count: 5;
    column-gap: $gutter;
    width: 100%;
    .tumblr-post{
      margin-bottom: 1rem;
      .tumblr-caption{
        display: inline-block;
        p{
          display: inline;
          font-family: $font-1, sans-serif;
          font-size: 1rem;
          margin-bottom: .5rem;
          br{
            display: none;
          }
        }
      }
    }
  }

  .container-feed-social{

    .container-instagram-feed{
      .container-instagram-element{
        margin-bottom: $gutter;
        a{
          position: relative;
          display: block;
          overflow: hidden;
          font-family: $font-1, sans-serif;
          &:hover{
            .caption-instagram-hover{
              opacity: 1;
            }
          }
          .caption-instagram-hover{
            background: rgba(255,255,255,0.6);
            padding: 10px;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all ease .3s;
            line-height: 20px;
          }
        }
      }
    }
  }
}