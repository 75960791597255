@import "slick";
@import "variable";
@import "font";
@import "style";
@import "jquery.mCustomScrollbar.scss";

@import "index/index-1";
@import "index/index-2";

@import "board/board-1";
@import "board/board-2";

@import "talent/talent-1";
@import "talent/talent-2";

@import "responsive";



@import "panel-theme";
@import "foundation-settings";
@import "foundation";
@import "motion-ui";

@import "fa-variables";
@import "font-awesome";

@include foundation-everything;
@include motion-ui-transitions;