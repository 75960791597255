/* Font */

@font-face {
  font-family: $font-1;
  src: url('../font/LibreBaskerville-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-1;
  src: url('../font/LibreBaskerville-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font-2;
  src: url('../font/Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font-2;
  src: url('../font/Muli-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
