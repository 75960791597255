.panel-control-theme{
  width: 150px;
  background: #fff;
  border: 1px solid #cccccc;
  border-bottom: 0;
  border-left: 0;
  position: fixed;
  bottom: 0;
  left: -150px;
  z-index: 100000;
  transition: all ease .4s;
  &.active{
    left: 0;
  }
  ul{
    li{
      padding-left: 10px;
    }
  }
  .open-close-panel-theme{
    background: #ffffff;
    border: 1px solid #cccccc;
    border-bottom: 0;
    border-left: 0;
    padding: 5px;
    position: absolute;
    right: -24px;
    bottom: -1px;
  }
}