* {outline:0;}

$blue: #0B36C3;

html {
    body {
        position: relative;
        color: $blue;
        
        a {
            color: inherit;
        }
    }
}

.width-centered {
    width: $main-width;
    margin-left: auto;
    margin-right: auto;
}

/* Title style */
.edito-t {
  font-family: $font-1;
  background-color: #fff;
  
  h1, h2, h3 {
    border-bottom: 1px solid $border-color-1;
    line-height: 30px;
    height: 17px;
    text-align: center;
    font-size: 1rem;
    margin: 4rem 0;
    
    span, a {
      font-weight: bold;
      letter-spacing: 4px;
      background: #ffffff;
      font-size: 2.3rem;
      padding: 1rem;
      font-family: $font-1, serif;
      line-height: 30px;
      height: 30px;
      text-transform: uppercase;
    }
    
    @media (max-width: 768px) {
        & {
            margin: 1rem 0;
        }
        
        &, span, a {
            height: auto;
            line-height: 59px;
        }
        
        span, a {
            display: block;
        }
    }
  }
  .sub-title{
    font-size: 1.15rem;
    line-height: 1.15rem;
    margin-bottom: 0;
    font-family: $font-2, serif;
  }
}


.no-pr{
  padding-right: 0!important;
}

.no-pl{
  padding-left: 0!important;
}

.container-slider .orbit-previous, .container-slider .orbit-next{
  color: #000000;
  font-size: 1rem;
}

.footer-main {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-bottom: 40px;
    
    @media (max-width: 600px) {
        padding-bottom: 4px;
        font-size: .8em;
    }
    
    li {
        display: inline-block;
        vertical-align: middle;
        font-family: $font-2, sans-serif;
        font-weight: 700;
        
        @media (max-width: 600px) {
            display: block;
            
            &.separator {display: none;}
        }
    }
}

/* Header */

#main-header {
  .container-logo{
    margin-top: 30px;
  }
  .is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu{
    left: 0;
    top: 38px;
    background: rgba(255,255,255, 0.9);
    text-align: left;
    z-index: 1000000;
    border: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 240px;
    @media (max-width: 639px) {
        padding-top: 0;
        padding-bottom: 0;
    }
    
    a{
      font-size: .9rem;
    }
  }
  .main-menu{
    .title-bar{
      display: none;
    }
    margin-top: 1rem;
    .top-bar input {
      height: 40px;
    }
    .menu.medium-horizontal > li{
      display: inline-block;
      text-transform: uppercase;
      
      @media (max-width: 639px) {
        padding-left: 0;
        
        .is-dropdown-submenu {
            display: block;
            padding-left: 16px;
        }
      }
      
      a {
        font-family: $font-2;
        font-size: 1.2rem;
        letter-spacing: 4px;
        padding: 0.3rem 1.4rem;
        line-height: 1.7em;
        
        &.nav-sub-item {
          font-size: .9rem;
        }
        
        @media (max-width: 920px) {
            padding-left: 0.6rem;
            padding-right: 0.6rem;
        } @media (max-width: 766px) {
            font-size: 1rem;
            letter-spacing: 2px;
            padding-left: 0.4rem;
            padding-right: 0.4rem;
        }
      }
    }
    .menu-item-search {
        float: left;
        position: relative;
        top: 3px;
        font-size: 1.3em;
        
        @media (max-width: 639px) {
            display: none;
        }
    }
    .menu-item-social{
      float: right;
      
      
      @media (max-width: 766px) {
        position: relative;
        top: -4px;
      } @media (max-width: 639px) {
          float: none;
          
          &:after {
            content: '';
            clear: both;
          }
      }
      
      li{
        text-align: center;
        &:first-child{
          text-align: right;
          input{
            float: left;
            border: 0;
            border-bottom: 1px solid $border-color-1;
          }
          button{
            margin-right: 10px;
            font-family: $font-2, serif;
            font-weight: 600;
          }
          .autocomplete-suggestion{

            em{
              text-decoration: none;
            }
            font-family: $font-2, serif;
          }
        }
        a{
          padding-left: 0;
          font-size: 1.3rem;
        }
      }
    }
  }
  .menu-icon:after{
    background: #a9a9a9;
    box-shadow:0 7px 0 #a9a9a9, 0 14px 0 #a9a9a9;
  }
  
  #search_form_search {
    display: inline-block;
  }
}

/* Footer */

#main-footer{
  margin: 3rem 0 1rem;
  .menu > li{
      display: inline-block;
      font-family: $font-1, sans-serif;
      a{
        padding: 0;
      }
    }
  .gototop{
    display:none;
    width: 55px;
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}



/* Errors */

.form-errors{

}





/* Become model page */

.form-become-model{

}

#become-form {
    padding-top: 80px;
}

.formular{
    .g-recaptcha {
        position: relative;
        height: 78px;
        
        margin-top: 40px;
        margin-bottom: 40px;
        
        & > * {
            position: absolute;
            top: 0;
            right: 0;
            
            @media (max-width: 870px) {
                position: static;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    
  .note-limit-upload {
    margin-top: 60px;
  }
  
  .form{
    margin-top: 30px;
    height: 2.5rem;
    &:first-child {
        &, label {
            margin-top: 0;
        }
    }
    
    @include clearfix;
    & label{
      width: 30%;
      float: left;
      text-transform: uppercase;
      margin-top: 7px;
      cursor: pointer;
      font-family: $font-2, serif;
      font-size: 1rem;
      color: inherit;
    }

    &.number input, &.text input{
      width: 70%;
      float: left;
      margin-bottom: 0;
      border: 0;
      border-bottom: 1px solid $border-bottom-field;
    }
    
    &.textarea{
      height: 6rem;
      
      textarea{
        border: 0;
        border-bottom: 1px solid $border-bottom-field;
        width: 70%;
        float: left;
        margin-bottom: 0;
        height: 5.5rem;
      }
    }
    .choices-container{
      label{
        width: 50px;
        float: left;
        margin-bottom: 1rem;
      }
      input{
        width: 15px;
        float: left;
        margin-top: 9px;
        margin-bottom: 0;
      }
    }
    
    &.file{
      height: auto;
      border: 0;
      label{
        text-align: left;
        width: 100%;
        height: 35px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      input{
        position: absolute;
        left: -99999px;
      }
    }
  }
  
  .label-input-form {
    border-bottom: 1px solid $border-bottom-field;
    text-align: right;
    width: 70%;
    position: absolute;
    top: 3px;
    right: 0;
    
    label {
        color: #fff;
        background-color: $blue;
        display: inline-block;
        margin-right: 8px;
        font-family: $font-2;
        padding: 2px 28px;
        text-transform: uppercase;
        margin-bottom: 8px;
    }
  }
  
  .file-is-ready {
    position: relative;
    left: 170px;
    top: 13px;
  }
  
  .container-field-upload {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    height: 54px;
    
    img {
      position: absolute;
      top: 45px;
      width: calc(100% - #{$gutter} * 2);
      display: none;
    }
  }
  .container-robot-security{
    margin: 1rem 0;
  }
  .container-submit-become-form{
    text-align: right;
    
    input{
      width: 70%;
      background: $blue;
      font-family: $font-2;
      
      color: #fff;
      border: 0;
      padding: .7rem 1.4rem;
      text-transform: uppercase;
    }
    
    @media (max-width: 870px) {
        text-align: center;
    }
  }
}

/* contact */
.contact-video {
    position: relative;
    margin-top: 8px;
    margin-left: .625rem;
    margin-right: .625rem;
    
    .shape {
        img {
            width: 100%;
        }
    }
    
    iframe, video, audio, embed, object {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        width: 100%;
        height: 100%;
    }
}

.contact-address {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.container-contact-map{
  margin-top: 30px;
}

.contact-form-side {
    display: inline-block;
    vertical-align: top;
    width: 46%;
    
    &:first-child {
        margin-right: 32px;
    }
    
    @media (max-width: 870px) {
        display: block;
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
    }
}

.contact-text-side {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    
    &:first-of-type {
        margin-right: 18px;
    }
    
    @media (max-width: 1130px) {
        display: block;
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
    }
}

.container-adress{
  font-family: $font-2, serif;
  font-size: 1.1rem;
  padding: 2rem 10%;
  margin-bottom: 0;
  strong{
    
  }
}

.about-text {
    line-height: 1.7rem;
    margin-left: 64px;
    margin-right: 64px;
    
    li {
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    
    h1 {
        margin-bottom: 16px;
        height: auto;
        
        span {
            background: none;
            line-height: 1.1em;
        }
    }
}



/* talent page */

.talent-page{
  position: relative;
  .print-modal{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
#print-modal{
  p{
    &.title-print-modal{
      text-align: center;
      font-size: 1.1rem;
    }
    text-align: center;
  }
}

.container-slider{
  margin-top: 30px;
  height: calc(100vh - 352px);

  .orbit{
    height: inherit!important;
    .orbit-container{
      height: inherit!important;
      .orbit-previous, .orbit-next{
        width: 50%;
        height: 100%;
      }
      .orbit-previous{
        text-align: left;
      }
      .orbit-next{
        text-align: right;
      }
      .orbit-slide{
        height: inherit!important;
        .container-img-slider{
          height: inherit!important;
          display: table;
          .container-talent-info-slide{
            display: table-cell;
            vertical-align: middle;
          }
          .content-img-slider{
            display: table-cell;
            vertical-align: middle;
            img{

            }
          }
        }
      }
    }
  }
}

.container-talent-info-slide{
  h1{
    
    font-size: 2rem;
    border: 0;
    margin-bottom: 2rem;
    height: 2rem;
  }
  li{
    margin-bottom: 2vh;
    font-family: $font-2, serif;
  }
}

.second-measurement-info{
  margin-top: 1rem!important;
  &.menu > li{
    display: inline-block;
    font-family: $font-2, serif;
    margin-right: 1rem;
    strong{
      
    }
  }
}


.arrow-icons {
  width: 40px;
}

.print-icon {
  width: 20px;
  padding-top:20px;
}



/* Terms & conditions */
.term-condition{
    h1 {
        border: 0;
    }
    
    ul {
        list-style: none;
        padding-left: 0;
        
        li:before {
            content: '-';
            display: inline-block;
            width: 30px;
            margin-left: -30px;
        }
    }
}

.coming-soon {
  height:64vh;
}

.coming-soon-search {
  height:62vh;
}

