

/* responsive tablet */

@media screen and (max-width: 1300px) {
    .width-centered {
        width: auto;
    }
    
} @media screen and (max-width: 1280px){
  .container-feed-social{
    width: 100%;
    .facebook-feed-container.columns,.instagram-feed-container.columns,.twitter-feed-container{
      width: 33%;
      padding: 5px;
    }
  }
}
@media screen and (max-width: 1024px){

  .container-feed-social{
    width: 100%;
    .facebook-feed-container.columns,.instagram-feed-container.columns,.twitter-feed-container{
      width: 100%;
      padding: 10px;
    }
  }

  .facebook-feed-container.columns{
    width: 100%;
    max-width: 100%;
    .fb-page{
      max-width: 100%!important;
      *{
        max-width: 100%!important;
      }
    }
  }

  .instagram-feed-container.columns{
    width: 100%;
  }

}

