.board-2{
  &.talent-list{
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .container-talent-list-element{
      margin-top: 1.5rem;
      position: relative;
      width: 233px;
      &.columns{
        padding-right: .6rem;
        padding-left: .6rem;
      }
      img{
        display: none;
      }
      .content-img-talent-list{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        height: 294px;
        overflow: hidden;
        .name{

        }
        a{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &:hover{
        .content-img-talent-list{
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          -o-filter: grayscale(0%);
          -ms-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
      .hover-name-talent{
        margin-top: 6rem;
        width: 100%;
        display: block;
        font-family: $font-1, sans-serif;
      }
      .container-measurement-talent-list{
        display: none;
        visibility: hidden;
        opacity: 0;
        line-height: 15px;
        margin: 0;
        position: absolute;
        top: 0;
        width: calc(100% - .6rem * 2);
        height: 100%;
        text-align: center;
        visibility: hidden;
        .container-social-link{
          margin: 2.5rem .5rem 0;
          display: inline-block;
          font-family: $font-1, sans-serif;
          i{
            margin-left: 10px;
            font-size: 14px;
          }

        }
      }
      &:hover{
        .container-measurement-talent-list{
          background: rgba(255, 255, 255, .7);
          visibility: visible;
          height: 100%;
          display: block;
          opacity: 1;
        }
        .container-list-talent-name{
          visibility: hidden;
        }
      }
      .container-list-talent-name{
        color: #000;
        font-family: $font-1, serif;
      }

    }
  }
  .black{
    padding-top: 100px;
  }

  .container-talent-list-element{
    margin-top: 1.5rem;
    position: relative;
    p{
      font-family: $font-1, serif;
      margin-top: .3rem;
    }
    &:hover{
      p{
        visibility: hidden;
      }
      .content-info-talent{
        display: block;
      }
    }
    .content-info-talent{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.4);
      display: none;
      ul{
        margin: 0;
        li{
          text-align: center;
          margin-top: 30px;
          font-family: $font-1, serif;
          &:first-child{
            font-size: 1.75rem;
            font-weight: 600;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}