
/* Specific Variable */

$border-color-1: #808080;
$border-bottom-field: #ababab;
$gutter: .9375rem;
$grey: #95989A;

$font-1: 'Libre Baskerville';
$font-2: 'Muli';

$main-width: 1270px;

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}