
/* Board 1 */

#content.talent-list {
    ul {
        text-align: center;
    }
}

.board-1 {
    &.bw {
        .container-talent-list-element {
            &, &:hover {
                .content-img-talent-list {
                    -webkit-filter: grayscale(100%);
                            filter: grayscale(100%);
                }
            }
        }
    }
    
  .container-talent-list-element {
    margin-top: 1.5rem;
    position: relative;
    width: 380px;
    display: inline-block;
    float: none;
    vertical-align: top;
    @media (max-width: 390px) {
        width: 100%;
    }
    
    img{
      display: none;
    }
    
    .content-img-talent-list{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        height: 550px;
        overflow: hidden;
        
        transition: all ease 300ms;
    }
    
    &:hover{
        .content-img-talent-list{
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
            -o-filter: grayscale(0%);
            -ms-filter: grayscale(0%);
            filter: grayscale(0%);
        }
    }
    
    p{
      font-family: $font-2, serif;
      margin-top: .3rem;
    }
    &:hover{
      p{
        visibility: hidden;
      }
      .content-info-talent{
        display: block;
      }
    }
    .content-info-talent{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.4);
      display: none;
      ul.container-main-stat{
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 550px;
        padding-bottom: 60px;
        li {
          text-align: center;
          
          &.container-talent-name{
            font-size: 1.95rem;
            font-weight: 600;
            margin-top: 310px;
          }
        }
      }
    }
  }
  
  p.talent-name-p {
    font-size: 1.25rem;
    font-family: $font-1;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .container-social-media {
    font-family: $font-2;
    font-size: 1.2em;
    
    ul{
      display: block;
      text-align: center;
      padding: 0 30px;
      margin: 0;
      li{
        display: inline;
        margin-left: 15px;
      }
    }
  }



  /* talent list breakpoint */
  .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 470px;
  }
}