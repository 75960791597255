/* index-1 */

.landing-page {
    font-family: $font-2;
    background-color: #000;
    text-align: center;
    
    a:hover, a:focus {
        color: #000;
    }
    
    .container-instagram-header {
        a {
            color: $blue;
        }
    }
}

.splash-landing-page {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 10;
    
    @media (max-width: 600px) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        transform: none;
    }
    
    .logo {
        img {
            width: 655px;
        }
    }
    
    .social-links {
        font-size: 1.2em;
        padding-top: 30px;
        padding-bottom: 20px;
        
        @media (max-width: 600px) {
            display: none;
        }
        
        li {
            display: inline-block;
            
            a {
                display: block;
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
    
    .talents-access {
        @media (max-width: 600px) {
            margin-top: 40px;
        }
        
        ul {margin-left: 0;}
        
        li {
            display: inline-block;
            text-transform: uppercase;
            vertical-align: top;
            
            @media (max-width: 600px) {
                display: block;
                margin: 12px auto;
            }
            
            a {
                display: block;
                font-size: 1.3em;
                letter-spacing: 4px;
                padding-left: 10px;
                padding-right: 10px;
                
                @media (max-width: 600px) {
                    & + .sub {
                        display: block;
                    }
                }
                
                &:hover, &:focus {
                    & + .sub {
                        display: block;
                    }
                }
            }
        }
        
        .sub {
            text-align: left;
            padding-top: 8px;
            display: none;
            position: absolute;
            
            &:hover, &:focus {
                display: block;
            }
            
            li {
                display: block;
                font-size: .8em;
                
                a {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            
            @media (max-width: 600px) {
                position: static;
                padding-left: 16px;
                
                li {
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                    
                    a {
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                }
            }
        }
    }
}


.landing-slideshow-wrapper {
    z-index: 0;
    &, .cache, .landing-page-slider {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }
    
    .cache {
        background-color: rgba(#fff, .5);
        z-index: 2;
    }
}

.landing-page-slider {
    opacity: 0;
    transition: all linear 6s;
    z-index: 1;
    
    &.ready {
        opacity: 1;
    }
    
    .slick-list, .slide, .slick-track {
        height: 100%;
    }
    
    .slide {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    
    img {
        position: absolute;
        left: -9999px;
    }
}

.index-1{
  .home-slide{
    overflow: hidden;
    height: calc(100vh - 202px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    img{
      display: none;
    }
  }

  .tmblr-iframe{
    display: none!important;
  }

  .tumblr-container{
    -moz-column-count: 5;
    -moz-column-gap: $gutter;
    -webkit-column-count: 5;
    -webkit-column-gap: $gutter;
    column-count: 5;
    column-gap: $gutter;
    width: 100%;
    .tumblr-post{
      margin-bottom: 1rem;
      .tumblr-caption{
        display: inline-block;
        p{
          display: inline;
          font-family: $font-1, sans-serif;
          font-size: 1rem;
          margin-bottom: .5rem;
          br{
            display: none;
          }
        }
      }
    }
  }

  .container-feed-social{
    width: 1280px;
    margin: 0 auto;
    .facebook-feed-container.columns,.instagram-feed-container.columns,.twitter-feed-container{
      width: 425px;
    }
    .container-instagram-feed{
      .columns{
        padding-left: calc(#{$gutter} / 2);
        padding-right: calc(#{$gutter} / 2);
        margin-bottom: $gutter;
      }
    }
  }
}