/* talent page */
.mCSB_horizontal.mCSB_inside {
    & > .mCSB_container {
        margin-bottom: 16px;
    }
}

.talent-header-wrapper {
    margin-top: 32px;
}

.talent-go-back,
.talent-social {
    min-width: 300px;
    min-height: 1px;
    
    padding-top: 12px;
    
    a {
        color: $grey;
    }
}

.talent-go-back {
    float: left;
    
    a {
        text-transform: uppercase;
        font-family: $font-2;
        letter-spacing: 2px;
    }
}

.talent-social {
    float: right;
    font-size: 1.1em;
    font-family: $font-2;
    text-align: right;
    padding-top: 8px;
    
    li {
        display: inline;
        vertical-align: middle;
        padding: 0 16px;
    }
}

.talent-infos {
    margin-bottom: 24px;
    
    .name {
        font-size: 2.3em;
        font-family: $font-1;
        font-weight: bold;
        text-align: center;
        letter-spacing: 4px;
        
        @media (max-width: 750px) {
            clear: both;
        }
    }
}

.talent-list {
    margin-top: 12px;
    text-align: center;
    
    &.trimmed {
        font-size: 0.88em;
    }
    
    li {

        vertical-align: middle;
    }
}

.resp-talent-portfolio-wrapper {
    display: none;
    
    .item {
        margin-bottom: 16px;
        text-align: center;
        
        img {
            max-width: 100%;
        }
    }
    
    @media (max-width: 750px) {
        display: block;
    }
}

.talent-portfolio-wrapper {
    white-space: nowrap;
    overflow-x: auto;
    
    .slider-item {
        display: inline-block;
        vertical-align: top;
        margin-right: 16px;
        height: 590px;
        min-height: 400px;
        
        img {
            max-width: none;
            height: 100%;
        }
    }
    
    @media (max-width: 750px) {
        display: none;
    }
}

.talent-page.talent-1{
  position: relative;
  .print-modal{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
.talent-1{
  .container-slider{
    margin-top: 30px;
    height: calc(100vh - 322px);

    .orbit{
      height: inherit!important;
      .orbit-container{
        height: inherit!important;
        .orbit-previous, .orbit-next{
          width: 50%;
          height: 100%;
        }
        .orbit-previous{
          text-align: left;
        }
        .orbit-next{
          text-align: right;
        }
        .orbit-slide{
          height: inherit!important;
          .container-img-slider{
            height: inherit!important;
            display: table;
            
            .container-talent-info-slide{
              display: table-cell;
              vertical-align: middle;
            }
            .content-img-slider{
              display: table-cell;
              vertical-align: middle;
              
              img {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  .container-talent-info-slide{
    h1{
      
      font-size: 2rem;
      border: 0;
      margin-bottom: 2rem;
      height: 2rem;
    }
    li{
      margin-bottom: 2vh;
      font-family: $font-2, serif;
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 15px;
          }
        }
      }
    }
  }

  .second-measurement-info{
    margin-top: 1rem!important;
    &.menu > li{
      display: inline-block;
      font-family: $font-2, serif;
      margin-right: 1rem;
      strong{
        
      }
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 5px;
          }
        }
      }
    }
  }
}

#print-modal{
  width: 335px;
  border-radius: 8px;
  p{
    font-size: 12px;
  }
}


.container-social-media{
  position: relative;
  z-index: 20;
}


.menu-talent{
  margin-left: 30px;
  padding-top: 20px;
  li{
    display: inline-block;
    margin-right: 15px;
  }
}

.arrow-icons {
  width: 40px;
}

.minicons{
  width: 23px;
  position: relative;
  top:-1px;
}

.print-icon {
  width: 26px;
  padding-top: 0;
  margin-top: -1px;
}


.orbit-arrow-videos {
  width:10%!important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 10%;
  width: 75%;
  height: 60%;
}

/* PrettyPhoto */
.zoomed-image {
    vertical-align: middle;
    height: 100%;
    
    &.landscape {
        height: auto;
        max-width: calc(100% - 120px);
    }
    
    @media (max-width: 639px) {
        max-width: calc(100% - 120px);
        height: auto;
    }
}

.edito-t {
    .reveal-overlay {
        overflow-y: visible;
        overflow: visible;
    }
}

.reveal-overlay {
    .reveal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        white-space: nowrap;
        
        background-color: transparent;
        text-align: center;
        overflow-y: visible;
        overflow: visible;
        border: 0;
        
        .fa, .close {
            color: white;
            font-size: 4em;
        }

        .close {
            position: absolute;
            top: 7px;
            left: 0;
            font-size: 2em;
            z-index: 2;
        }

        .reveal-button-prev,
        .reveal-button-next {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            width: 50px;
            height: 100%;
            position: relative;
            
            [class*="fa-angle"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                
                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .reveal-button-prev {
            [class*="fa-angle"] {
                &:before {
                    right: 0;
                }
            }
        }
        .reveal-button-next {
            [class*="fa-angle"] {
                &:before {
                    left: 0;
                }
            }
        }
    }
}

.clearfix{
  content: '';
  display: block;
  clear: both;
}

.bloc-b{
  display: flex;
  flex-wrap: wrap;
}

.bloc-image{
  margin-bottom: 20px;

  img{
    width: 100%;
  }
}

.content-img-slider{
  height: 815px;
  background-size: cover!important;


}

@media screen and (max-width: 640px){

  .container-img-slider{
    height: auto!important;

  }

}



.container-img-slider{
  padding-left: 15px!important;
  padding-right: 15px!important;
  padding-top: 15px;
  padding-bottom: 15px;

}

.talent-list.inline li{
  display: inline-block;
}